<template>
    <div class="container_site">
        <div class="ubication_dimension">
            <p class="ubication_dimension--home" @click="$router.push({name: 'Home'})">Inicio</p>
            <img src="@/assets/img/arrow-breadcrumb.svg" class="ubication_dimension--breadcrumb">
            <p class="ubication_dimension--name_dimension">Quiénes somos</p>
        </div>
        <div class="container_about">
            <div class="container_about__description--title">
                Quiénes somos
            </div>
            <div class="container_about__main">
                <div class="about_main__element">
                    <a 
                        href="#shield"
                        v-smooth-scroll  
                        class="about_main__option option--lightgreen"
                        @mouseover="hoverShield = true"
                        @mouseleave="hoverShield = false"
                    >
                        <ShieldIcon :color="hoverShield ? '#FFFFFF' : '#A5D271'" />
                    </a>
                    <p class="about_main__text">Escudo</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#flag" 
                        v-smooth-scroll 
                        class="about_main__option option--mintgreen"
                        @mouseover="hoverFlag = true"
                        @mouseleave="hoverFlag = false"
                    >
                        <FlagIcon :color="hoverFlag ? '#FFFFFF' : '#69C061'" />
                    </a>
                    <p class="about_main__text">Bandera</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#Historia:"
                        v-smooth-scroll  
                        class="about_main__option option--purple"
                        @mouseover="hoverHistory = true"
                        @mouseleave="hoverHistory = false"
                    >
                        <HistoryIcon :color="hoverHistory ? '#FFFFFF' : '#6F73BF'" />
                    </a>
                    <p class="about_main__text">Historia</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#Reseña histórica" 
                        v-smooth-scroll 
                        class="about_main__option option--lightblue"
                        @mouseover="hoverReview = true"
                        @mouseleave="hoverReview = false"
                    >
                        <ReviewIcon :color="hoverReview ? '#FFFFFF' : '#23B7D9'" />
                    </a>
                    <p class="about_main__text">Reseña histórica</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#Geografía"
                        v-smooth-scroll 
                        class="about_main__option option--blue"
                        @mouseover="hoverGeography = true"
                        @mouseleave="hoverGeography = false"
                    >
                        <GeographyIcon :color="hoverGeography ? '#FFFFFF' : '#3E73BE'"/>
                    </a>
                    <p class="about_main__text">Geografía</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#Ecología"
                        v-smooth-scroll 
                        class="about_main__option option--green"
                        @mouseover="hoverEcology = true"
                        @mouseleave="hoverEcology = false"
                    >
                        <EcologyIcon :color="hoverEcology ? '#FFFFFF' : '#24A638'"/>
                    </a>
                    <p class="about_main__text">Ecología</p>
                </div>
                <div class="about_main__element">
                    <a 
                        href="#Economía"
                        v-smooth-scroll  
                        class="about_main__option option--blue"
                        @mouseover="hoverEconomy = true"
                        @mouseleave="hoverEconomy = false"
                    >
                        <EconomyIcon :color="hoverEconomy ? '#FFFFFF' : '#3E73BE'"/>
                    </a>
                    <p class="about_main__text">Economía</p>
                </div>
            </div>
            <div class="container_about__header" id="shield">
                <div class="container_about__description">
                    <h3 class="container_about__description--subtitle"><strong>Escudo</strong></h3>

                    <div class="container_about__description--description">
                        {{About.description}}
                    </div>
                    
                </div>
                <!--<div class="container_about__img_about" :style="{'background-image': `url(${About.img})`, 'background-size': 'cover', 'background-position': 'center'}">
                </div>-->
                <img :src="About.img" style="max-height: 353px;"/>
            </div>


            <div class="container_about__body" id="flag">
                <div class="about_header__flag">
                    <div class="flag_content">
                        <h3 class="flag_content__title" ><strong>Bandera</strong></h3>
                        <p>El fondo blanco es símbolo de paz y tranquilidad que reina entre las gentes del municipio, sobre él se destaca un triángulo verde, símbolo de la esperanza que abriga nuestros pueblos, que buscan el progreso y mejoramiento. Las estrellas son el n mero de veredas que constan en el Municipio de El Retiro.</p>
                    </div>
                    <img class="flag_icon" src="@/assets/img/flag_retiro.jpeg">
                </div>
            </div>

            <div class="container_about__body" >
                <div v-html="AboutInformation_text" >
                  
                </div>
            </div>

            
            
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            About: {
                description: null,
                information_text: '',
                img: null,
                townhall: null,
                video: null,
            },
            hoverShield: false,
            hoverHistory: false,
            hoverReview: false,
            hoverEcology: false,
            hoverEconomy: false,
            hoverGeography: false,
            hoverFlag: false,
            AboutInformation_text: '',
 
        }
    },
    methods: {
        getAbout(){
            this.$http.get('townhalls/getAbout/'+this.getClient().id).then(response => {
                this.About = {
                    description: response.body.about.description,
                    information_text: response.body.about.information_text,
                    img: response.body.about.img,
                    townhall: response.body.about.townhall_id,
                    video: response.body.about.video
                }
                console.log(response.body)

                String.prototype.allReplace = function(obj) {
                    var retStr = this;
                    for (var x in obj) {
                        retStr = retStr.replace(new RegExp(x, 'g'), obj[x]);
                    }
                    return retStr;
                };

                this.AboutInformation_text = this.About.information_text.allReplace({
                        '<strong>Historia:</strong>': '<strong id="Historia:">Historia:</strong>', 
                        '<strong>Reseña histórica</strong>': '<strong id="Reseña histórica">Reseña histórica</strong>',
                        '<strong>Geografía</strong>': '<strong id="Geografía">Geografía</strong>',
                        '<strong>Ecología</strong>': '<strong id="Ecología">Ecología</strong>',
                        '<strong>Economía</strong>': '<strong id="Economía">Economía</strong>'
                    });

                console.log('info',this.AboutInformation_text)
            }).catch(error => {
                console.log('Error al consultar About', error)
            })
        },

    },
    created() {
        this.getAbout();
    },
}
</script>
